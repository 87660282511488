import React from 'react'
import { ShapeDivider } from '@refeb/shape-divider'
import { H1, H2, P } from '@refeb/typography'
import { SidebarLayout } from '@refeb/sidebar-layout'
import { Table, TableBody, TableData, TableRow } from '@refeb/table'
import { Particles } from '@refeb/particles'

import backgroundImage from './bg2.jpg'

const tableInfo = {
  'Input Voltage': '110/115/120/208/ 220/230/240 /460/ 480/ 575 V',
  'Input Phase': '1-Phase , 3-phase',
  'Input Amperage': '50 / 60 Hz',
  'Current Type': ' DC, 3 phase, AC',
  'Amperage Range': '1-800 A',
  'Weld Output': 'CV - CV',
  'Industries interest':
    'Racing / Customizing / Restoring / DIY / Home Hobbyist / Farm and Ranch / Metal Art / Sculptures / Manufacturing / metal art / Aerospace / Yacht / repair',
  'Weldable Metals': 'Steel Stainless Steel Aluminum , special material'
}

export default function Layout () {
  return (
    <div>
      <ShapeDivider
        title='TIG or GTAW'
        description='GTAW (Gas Tungsten Arc Welding) or TIG (Tungsten Inert Gas)'
        button={null}
        image={backgroundImage}
      />
      <SidebarLayout>
        <H1>GTAW (Gas Tungsten Arc Welding) or TIG (Tungsten Inert Gas)</H1>
        <P>
          In this weldings type, the arc is generated by electricity current
          (this is so good for all equipment)) flow between a tungsten electrode
          and work piece that creates a weld pool. In order to fill the weld
          pool, welder feed the filler wire in to the weld pool using his hand.
          Weld pool protection is held by an inert gas such as Argon, Helium or
          a mix of them. The gas type is selected base of working piece alloying
          elements and injected from one side or both side. During GTAW, welder
          should have high skill to use both hands and choose right welding
          parameters such as voltage, amps, gas type, gas flow, tungsten alloy
          type and filer type.
        </P>
        <P>
          Our specialists at MHMET are ready to discuss with you about your
          needs and desired applications to choose right equipment. Our
          equipments are available in following conditions:
        </P>
        <Table>
          <TableBody>
            {Object.keys(tableInfo).map((item) => {
              return (
                <TableRow key={item}>
                  <TableData>{item}</TableData>
                  <TableData>{tableInfo[item]}</TableData>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
        <P>
          TIG (tungsten inert gas) or GTAW (gas tungsten arc welding) is a
          process where melting is produced bye heating with an arc struck
          between a non-consumable tungsten electrode and work piece.
        </P>
        <P>
          An inert gas is used to shield the electrode and weld zone to prevent
          oxidation of the tungsten electrode and atmospheric contamination of
          the weld and hot filler wire.
        </P>
        <P>
          tungsten is used because it has a melting point of 3370 c, which is
          well above any other common metal
        </P>
      </SidebarLayout>
      <Particles>
        <H2>Equipments</H2>
        <P>description about equipments</P>
      </Particles>
    </div>
  )
}
