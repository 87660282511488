import React from 'react'
import { SearchBox } from '@refeb/search-box'
import { Button } from '@refeb/button'
import { Input } from '@refeb/input'
import { SidebarLayout } from '@refeb/sidebar-layout'
import { H1, P } from '@refeb/typography/build/Typography'
import { ProductItem } from '@refeb/product-item'
import { Pagination } from '@refeb/pagination'

import image from './product.jpg'

const items = [
  '  گاز CO2 با خلوص 100%',
  'گاز Argon+15 ~20% O2',
  'گاز Argon+1~5 % o2',
  'گاز Argon+ 2-5% O2',
  'گاز Argon+ 5-10% O2',
  'گار Argon+ 10-15% O2',
  'گاز Argon+ 5-10% CO2',
  'گاز Argon+Helium 0.5-2% CO2',
  'گاز Argon+O2/CO2 mixture'
]

export default function Layout () {
  return (
    <div style={{ padding: '1em 0' }}>
      <SidebarLayout>
        <div className='search'>
          <SearchBox
            Button={Button}
            Input={Input}
            placeholder='Search ...'
            onClick={(e) => console.log(e)}
          />
          <H1>MIG Gas Method</H1>
        </div>
        <P>
          welded and seamless wrought steel pipe asme B 36.10m stainless steel
          pipe ASME B36.19M
        </P>
        <div className='products'>
          {items.map((item, index) => {
            return (
              <div key={index} className='product-item'>
                <ProductItem id={String(index)} images={[image]} title={item} variant='' />
              </div>
            )
          })}
        </div>
        <Pagination useIcon count={10} currentPage={1} onChange={() => {}} />
      </SidebarLayout>
    </div>
  )
}
