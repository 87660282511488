import React from 'react'
import { ShapeDivider } from '@refeb/shape-divider'
import { SidebarLayout } from '@refeb/sidebar-layout'
import { H1, P, H2 } from '@refeb/typography'
import { List, ListItem } from '@refeb/list'
import { TextBox } from '@refeb/text-box'
import styled from 'styled-components'

import backgroundImage from './bg.jpg'

const LightPrimaryTextBox = styled(TextBox)`
  background-color: ${(props) => props.theme.colors.lightPrimary};
`
const PrimaryTextBox = styled(TextBox)`
  background-color: ${(props) => props.theme.colors.primary};
`
const DarkPrimaryTextBox = styled(TextBox)`
  background-color: ${(props) => props.theme.colors.darkPrimary};
`

const listItems = [
  'Welding machine',
  'welding material',
  'joint parameter',
  'welding parameter',
  'shielding parameter (perge)',
  'pre-heat',
  'heat treatment',
  'tensile test',
  'charpy test',
  'Mechanical Property test',
  'metallugraphy',
  'Hardness test',
  'replica'
]
const paragraphs = [
  {
    title: 'جوشکاری چدن',
    description: ` با توجه به ساختار متالوژیکی چدن ها عمدتا جوشکاری آنها بسیار مشکل می
    باشد و در صورت عدم رعایت نکات خاص جوشکاری این متریال مستعد به ترک است
    جهت انجام صحیح جوشکاری چدن نیاز به دستورالعمل خاص جوشکاری این نوع
    متریال می باشد و نیاز به مهارت خاصی دارد و ما می توانیم با توجه به
    درخواست مشتری نسبت به جوشکاری و یا ارایه دستورالعمل جوشکاری و ارزیابی
    مهارت جوشکار و آموزش جوشکار با توجه به شرایط مشتری اقدام نماییم.`
  },
  {
    title: `سوپر آلیاژ`,
    description: `برای جوشکاری این نوع آلیاژ نیاز به دستورالعمل مناسب جوشکاری دارند، این نوع متریال پس از گذشت زمان در سرویس بودن خاصیت جوش پذیری آنها کاهش می یابد و عمدتا در حین فرآیند جوشکاری ترک در آنها بوجود می آید مخصوصا در اتصال نو به کهنه و نیاز به آلیاژ سازی در سطح فلز دارند، ما می توانیم با توجه درخواست مشتری پس از بازرسی از تجهیز و یا pipe نسبت به جوشکاری، ازایه دستورالعمل جوشکاری و ارزیابی مهارت جوشکار اقدام نماییم.`
  },
  {
    title: `دابلکس`,
    description: `این نوع الیاژ با توجه به عناصر آلیاژی موجود در آن در حین جوشکاری خاصیت خود را از دست می دهند و نیاز به اندازه گیری پارامترهای متالوژیکی پس از جوشکاری دارند، جهت رسیدن به خاصیت متاوژیکی مناسب باید تمهیدات لازم قبل از شروع جوشکاری و در حین جوشکاری رعایت گردد و از نکات مهم آن جوشکاری دابلکس به گریدهای پایین تر فولاد مانند S.S modified شده می باشد، ما می توانیم با توجه درخواست مشتری پس از بازرسی از تجهیز و یا pipe نسبت به جوشکاری، ازایه دستورالعمل جوشکاری و ارزیابی مهارت جوشکار اقدام نماییم.`
  },
  {
    title: `تعمیر ترک های HTHA`,
    description: `بسیاری از فولادها با توجه به طراحی تجهیز در دمای بالا کار می کنند  با توجه به اینکه عمدتا در طراحی پارامترهای مهم از جمله پارامتر لارسون میلر در آنها رعایت شده است با این حال پس از مدت زمان درسرویس بودن دچار ترک هیدورژنی می گردند، با درنظر گرفتن اینکه ساخت دستگاه نو بسیاز زمانبر می باشد و هزینه گزافی دارند و این تجهیزات عمدتا تجهیزات critical هستند و اینکه از سرویس خارج شدن آنها باعث shut down محموعه به مدت طولانی می شود که علاوه بر هزینه ساخت تجهیز می باشد؛ ما می توانیم مطابق استاندارد ساخت نسبت به تعمیر آتها اقدام کنیم و یا دستورالعمل تعمیراتی، مشاوره در حین تعمیر در صورت داشتن پرسنل مجرب در آن شرکت اقدام نماییم. زمان تعمیرات عمدتا بسیار گوتاه بوده و هزینه به مراتب کمتر از ساخت دارد و با پایش مستمر تجهیز در بلند مدت می توان از سلامت آن مطمین گردید. این تعمیرات دارای مهارت خاص برشکاری، مهار کردن تجهیز، جوشکاری پیچیده؛ عمیات حرارتی، تست های NDT & AnDT خاص می باشد.`
  },
  {
    title: `رفع نشتی و جوشکاری در حین سرویس`,
    description: `از عمده  مشکلات پیش آمده در اکثر صنایع بروز تخریب و ایجاد نشتی در سیستم می باشد و نیاز به از سرویس خارج کردن تجهیز و یا خطوط لوله، با توجه به سیال فرآیندی و شرایط فرآیندی می تواند با وجود نشتی نسبت به رفع بطور موفت آن مطابق استاندارد اقدام نمود که باعث کاهش هزینه از سرویس خارج تجهیز و یا خطوط لوله  و همین طور هزینه از دست دادن سیال می شود و تا زمان shut down بعدی تجهیز و یا خطوط لوله بع کار خود ادامه می دهد، این نشتی عمدتا در سیستم آب کولینگ، استیم کندانس، بخار و ... قابل انجام است. `
  }
]
const TextBoxIcon = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='52'
    height='52'
    viewBox='0 0 24 24'
  >
    <path d='M24 0l-6 22-8.129-7.239 7.802-8.234-10.458 7.227-7.215-1.754 24-12zm-15 16.668v7.332l3.258-4.431-3.258-2.901z' />
  </svg>
)
export default function Layout () {
  return (
    <div>
      <ShapeDivider
        title='Welding Procedure (WPS)'
        image={backgroundImage}
        shape2
        centerText
        button={null}
        description=''
      />

      <SidebarLayout>
        <H1>Welding Procedure (WPS)</H1>
        <P />
        <P>
          WPS یک دستورالعل اجرایی فرآیند جوشکاری که براساس استاندارد ساخت الزامی
          می باشد، با توجه به فرآیند های هر کدام یه سری پارامترهای متغییر ضروری
          و غیر ضروری جهت انجام دارند که با در نظر گرفتن استاندارد ساخت، فرآنید
          جوشکاری و متریال مورد جوشکاری این پارمترها تغییر می کند؛ این خدمات به
          انجام صحیح جوشکاری و انتخاب متریال مصرفی، انتخاب پارامترهای روش
          جوشکاری، انتخاب دمای پیش گرم، انتخاب عملیات حرارتی مورد نیاز، انتخاب
          محافظت از جوش، پارامترهای جوشکاری (آمپر و ...) را مشخص می کند و حصول
          اطمینان از کیفیت جوش، خواص مکانکی و فیزیکی جوش را می دهد و هم مدرکی
          برای اثبات انجام صحیح جوشکاری می باشد.
        </P>
        <List flush>
          {listItems.map((item, index) => (
            <ListItem key={index}>{item}</ListItem>
          ))}
        </List>

        <P>
          کاربرد: صنایع نفت، گاز پتروشیمی، نیروگاه حرارتی، بویلر، کارخانه، صنایع
          خودرو، کشتی سازی
        </P>
        {paragraphs.map((item, index) => (
          <div key={index}>
            <H2 secondary>{item.title}</H2>
            <P>{item.description}</P>
          </div>
        ))}
      </SidebarLayout>
      <div className='text-box-wrapper'>
        <div className='text-box-item'>
          <LightPrimaryTextBox
            title='Other Service'
            description='Ispendisse consectetur fringilla luctus usce id mi diam, non ornare.'
            icon={TextBoxIcon}
          />
        </div>
        <div className='text-box-item'>
          <PrimaryTextBox
            title='Other Service'
            description='Ispendisse consectetur fringilla luctus usce id mi diam, non ornare.'
            icon={TextBoxIcon}
            darker
          />
        </div>
        <div className='text-box-item'>
          <DarkPrimaryTextBox
            title='Other Service'
            description='Ispendisse consectetur fringilla luctus usce id mi diam, non ornare.'
            icon={TextBoxIcon}
            darkest
          />
        </div>
      </div>
    </div>
  )
}
