import React, { useState } from 'react'
import { ThemeProvider } from 'styled-components'
import { Menu, MenuItem, Brand } from '@refeb/menu'

import Equipments from './Layouts/Equipments'
import Industries from './Layouts/Industries'
import Services from './Layouts/Services'
import Products from './Layouts/Products'
import Manufacturing from './Layouts/Manufacturing'

import Footer from './Footer'

import './App.css'

const theme = {
  // colors: {
  //   primary: '#a3acc9', // '#27672E',
  //   darkPrimary: '423d4c', // '#19421d',
  //   lightPrimary: '#6884b6', // '#7FBE39',
  //   textIconPrimary: '#484848',
  //   accent: '#d19e73', // '#ffc300',

  //   primaryText: '#1f1f1f',
  //   secondaryText: '#777777',
  //   tertiaryText: '#c2c2c2',
  //   divider: '#4c5667',

  //   darkBackground: '#f7f9fb',
  //   darkerBackground: '#eff1f4',
  //   lightBackground: '#eeeeee',
  //   lighterBackground: '#ffffff'
  // },

  fonts: {
    primary: 'Poppins, sans-serif',
    secondary: 'Poppins, sans-serif'
  },

  sizes: {
    mobileS: '320px',
    mobileM: '375px',
    mobileL: '425px',
    tablet: '768px',
    laptop: '1024px',
    laptopL: '1440px',
    desktop: '2560px'
  }
}
const colorSets = [
  {
    primary: '#a3acc9',
    darkPrimary: '423d4c',
    lightPrimary: '#6884b6',
    textIconPrimary: '#484848',
    accent: '#d19e73'
  },
  {
    primary: '#27672E',
    darkPrimary: '#19421d',
    lightPrimary: '#7FBE39',
    textIconPrimary: '#484848',
    accent: '#ffc300'
  },
  {
    primary: '#3a3029',
    darkPrimary: '#343436',
    lightPrimary: '#8f7714',
    textIconPrimary: '#484848',
    accent: '#6f2524'
  },
  {
    primary: '#1c588c',
    darkPrimary: '#222f40',
    lightPrimary: '#a7c6da',
    textIconPrimary: '#484848',
    accent: '#594941'
  },
  {
    primary: '#94b6c2',
    darkPrimary: '#4c7eb1',
    lightPrimary: '#a9bac4',
    textIconPrimary: '#484848',
    accent: '#eeeeee'
  }
]

function App () {
  const [component, setComponent] = useState('Products')
  const [colorSet, setColorSet] = useState(0)

  function renderContent () {
    switch (component) {
      case 'Equipments':
        return <Equipments />
      case 'Industries':
        return <Industries />
      case 'Services':
        return <Services />
      case 'Products':
        return <Products />
      case 'Manufacturing':
        return <Manufacturing />

      default:
        return <Equipments />
    }
  }
  const mainColors = colorSets[colorSet]
  const colors = {
    primaryText: '#1f1f1f',
    secondaryText: '#777777',
    tertiaryText: '#c2c2c2',
    divider: '#4c5667',

    darkBackground: '#f7f9fb',
    darkerBackground: '#eff1f4',
    lightBackground: '#eeeeee',
    lighterBackground: '#ffffff'
  }
  const colorsToUse = Object.assign({}, colors, mainColors)
  return (
    <ThemeProvider theme={Object.assign({}, theme, { colors: colorsToUse })}>
      <Menu maxWidth={1280}>
        <Brand style={{ color: 'black' }}>MHMET</Brand>
        <MenuItem
          title='Equipments'
          onClick={() => setComponent('Equipments')}
        />
        <MenuItem
          title='Industries'
          onClick={() => setComponent('Industries')}
        />
        <MenuItem title='Services' onClick={() => setComponent('Services')} />
        <MenuItem title='Products' onClick={() => setComponent('Products')} />
        <MenuItem
          title='Manufacturing'
          onClick={() => setComponent('Manufacturing')}
        />
        <MenuItem title='Themes' onClick={() => {}}>
          {colorSets.map((set, index) => {
            return (
              <MenuItem
                title={
                  <div style={{ display: 'flex' }}>
                    {Object.keys(set).map((colorName) => {
                      const backgroundColor = set[colorName]
                      console.log({ set, backgroundColor, colorName })
                      return (
                        <span
                          style={{
                            backgroundColor,
                            width: '30px',
                            height: '30px'
                          }}
                        />
                      )
                    })}
                  </div>
                }
                onClick={() => setColorSet(index)}
              />
            )
          })}
        </MenuItem>
      </Menu>
      {renderContent()}
      <Footer />
    </ThemeProvider>
  )
}

export default App
